import { SUBMIT_R3_COMPLETE } from "./URL";
import axiosInstance from "./axiosInstance";

export const submitRound = async (
  candidateId,
  candidateProfile,
  isEligible
) => {
  let Config = {
    // headers: {
    //   Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    // },
    header: {},
  };

  const URL = SUBMIT_R3_COMPLETE;
  const data = {
    // id: "dev.wissda@gmail.com",
    id: candidateId,
    // updating below line to pass value dynamically based on eligibility , because sourabh and lakshmi told to pass it as it is necessary according to them to set the status of this round 16/01/25
    status: isEligible,
    // status: true,
    stageId: candidateProfile.stageId,
    clientId: candidateProfile.clientId,
  };
  return await axiosInstance.post(URL, data, Config).then((res) => res.data);
};
